@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
  font-family: 'Roboto', sans-serif;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0,0,0,.5);
}

.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0,0,0,.7);
}

